import React, { FC } from 'react';

import MarketplaceList from './list';

export interface IMarketplace {
  app: string;
}

const Marketplace: FC<IMarketplace> = ({ app }) => {
  return <MarketplaceList />;
};

export default Marketplace;
