import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import ApiUser from '@/api/user';
import { EWebsiteTabNames } from '@/bi/bi-constants';
import { viewWebsiteTabsBiEvents } from '@/bi/events/view-website-tabs-bi-events';
import CONSTANTS from '@/common/constants';
import utils, { isBrowser } from '@/common/utils';
import { useAuth } from '@/context/auth-context';

import ChampBox from '../collectibles-marketplace/components/champ-box/champ-box';
import { CountCollectibles } from '../collectibles-marketplace/components/count';
import {
  gamesAndDownloadUrls,
  sortMarketPlaceNfts,
} from '../collectibles-marketplace/helpers';

const MarketplaceList = () => {
  let count = 0;
  const { user } = useAuth();
  const [pageSize] = useState(10);
  // const [nftCount, setNftCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  // const [nftIds, setNftIds] = useState<any>('');
  const [pageNumber, setPageNumber] = useState(0);
  const [endMessage, setEndMessage] = useState('');
  const [isDomLoaded, setLoadDom] = useState(false);
  const [listingCount, setListingCount] = useState(0);
  const [allNfts, setAllNfts] = useState<Array<any>>([]);
  // const [activeIndex, setActiveIndex] = useState<Array<any>>([0]);
  const [processedNfts, setProcessedNfts] = useState<Array<any>>([]);
  // const [copyProcessedNfts, setCopyProcessedNfts] = useState<Array<any>>([]);

  const gameIds: Array<any> = ['onjoyride.tennischamps'];

  const getListingCount = async () => {
    try {
      const data = await ApiUser.getMarketplaceListingCount(
        gameIds[0],
        user?.appUser.user.id,
      );
      setListingCount(data);
    } catch (e) {
      // BI event maybe
      console.error('getNfts error');
    }
  };

  const getNfts = async (pageNumber: number | any) => {
    try {
      // setPageNumber(pageNumber + 1);
      const data = await ApiUser.getNftForMarketPlace(
        gameIds[0],
        user?.appUser?.user?.id || '0',
        pageNumber,
        pageSize,
      );
      if (data.length === 0 || data.length < pageSize) {
        setEndMessage(data.length === 0 ? 'Nothing to show' : '');
        setHasMore(false);
      }
      setAllNfts(allNfts !== null ? allNfts.concat(data) : data);
      handelMultipleChamps(allNfts !== null ? allNfts.concat(data) : data);
      setLoadDom(true);
    } catch (e) {
      // BI event maybe
      console.error('getNfts error');
    }
  };

  const getDownloadUrlOfGame = (gameId: any) => {
    const game = gamesAndDownloadUrls.find((item) => item.id === gameId);
    return game?.url || '';
  };

  const handlePageClick = () => {
    //Method to increment the page number whenever user scrolls to the bottom
    // console.log(`User requested page number which is offset ${pageNumber}`);
    setPageNumber(pageNumber + 1);
  };

  const handelMultipleChamps = (champs: any) => {
    const allGames: Array<any> = [];
    // get all unique game-ids
    gameIds.forEach((id) => {
      const nfts =
        champs?.filter(
          (item: any) => item.characterType === 'NFT' && item?.appId === id,
        ) || [];
      const mintPass =
        champs?.filter(
          (item: any) =>
            item.characterType === 'MINT_PASS' && item?.appId === id,
        ) || [];
      allGames.push({
        appId: id,
        nfts,
        mintPass,
        url: getDownloadUrlOfGame(id),
      });
    });
    utils.sortGames(allGames);
    const games: any = [];
    const nftIds: any = [];
    let nftsCount = 0;
    allGames.forEach((game) => {
      games.push(utils.getGameName(game.appId) + '(' + game.nfts.length + ')');
      game.nfts.forEach((nft: any) => {
        nftIds.push(
          nft.nft.nftIndex +
            '_' +
            nft.nft.contractId +
            '_' +
            nft.nft.blockchain,
        );
      });
      // setNftIds(nftIds.toString());
      nftsCount = nftsCount + game.nfts.length;
      // setNftCount(nftsCount);
    });
    viewWebsiteTabsBiEvents.userIsOnSomeTab(
      EWebsiteTabNames.COLLECTIBLES,
      games.toString(),
      nftIds.toString(),
      [],
      nftsCount,
      0,
    );
    setProcessedNfts(allGames);
    // setCopyProcessedNfts(allGames);
    // dispatch(getAllNfts(allGames));
  };

  useEffect(() => {
    if (
      CONSTANTS.DISABLE_MARKETPLACE &&
      isBrowser() && window.location.pathname.includes('marketplace')
    ) {
      console.warn('marketplace is not yet enabled');
      return;
    }
    viewWebsiteTabsBiEvents.viewWebsite(EWebsiteTabNames.COLLECTIBLES);
    getListingCount();
    // getNfts(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(pageNumber, 'pageNumber');

    getNfts(pageNumber);
    return;
    // eslint-disable-next-line
  }, [pageNumber]);

  return (
    <InfiniteScroll
      dataLength={listingCount}
      next={handlePageClick}
      hasMore={hasMore}
      loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
      endMessage={
        <p style={{ textAlign: 'center' }}>
          <strong>{endMessage}</strong>
        </p>
      }
    >
      <div
        className={`joyrideChampRow game-card-mobile ${
          isDomLoaded ? 'joyrideChampBorder' : ''
        }`}
      >
        {isDomLoaded &&
          processedNfts &&
          processedNfts.map(({ nfts, mintPass, appId, url }, i) => {
            return (
              <div className="card game-card-container" key={'nft-id-' + i}>
                <>
                  {nfts.length ? (
                    <div className="mint-pass-text flex">
                      <span className="champs game-name">Champs</span>
                      <CountCollectibles countNumber={nfts?.length} />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="row champ-container pr-md-4">
                    {nfts?.length
                      ? sortMarketPlaceNfts(nfts)?.map((nft) => {
                          if (nft.nft?.media && nft.nft?.media[0]?.file) {
                            if (count === 0) {
                              count++;
                            }
                          }
                          return (
                            <ChampBox
                              key={nft.id}
                              nft={nft}
                              parentComponent={'marketplace'}
                            />
                          );
                        })
                      : null}
                  </div>
                  {/* <div
                    key={'banner-' + i}
                    className="explore-Joyride-Ecosystem-wrp champs-banner-margin collectibles-banner"
                  >
                    <Banner
                      handelClick={() => handelBuyNowBannerClick(appId)}
                      pageType={'collectibles-list'}
                      backgroundImageUrl={''}
                      buttonText={'Buy Now'}
                      text={handelBuyNowBannerText(appId, nfts, i)}
                    />
                  </div> */}
                </>
              </div>
            );
          })}
        <hr />
        <hr />
      </div>
    </InfiniteScroll>
  );
};

export default MarketplaceList;
