import React from 'react';

import '@/assets/scss/racket-rampage.scss';
import { withDashboardLayout } from '@/components/layout/dashboard-layout';
import FiltersLayout from '@/components/layout/filters-layout';
import Marketplace from '@/features/marketplace';
import NavPills from '@/features/racket-rampage/nav-pills';
import RewardEvent from '@/features/racket-rampage/reward-event';

const RRMarketplace = () => {
  return (
    <>
      <FiltersLayout className="racket-rampage">
        <>
          <RewardEvent />
          <NavPills app="marketplace" />
          <Marketplace app="racket-rampage" />
        </>
      </FiltersLayout>
    </>
  );
};

export default withDashboardLayout(RRMarketplace);

export function Head() {
  return <title>Superchamps Wallet | Racket Rampage</title>;
}
